import React from 'react';
import Layout from '../components/Layout';
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';
import { Link, Button } from 'gatsby-material-ui-components';
import {
    Divider,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Typography,
} from '@mui/material';
import styled from '@emotion/styled';

const ContactPage = ({ data }) => {
    return (
        <>
            <Helmet>
                <title>Kontakt - {data.site.siteMetadata.title}</title>
            </Helmet>
            <Layout>
                <Typography variant="h2" component="h1">
                    Kontakt
                </Typography>

                <Typography>
                    Pokiaľ máte otázky alebo záujem o školenie, tak ma môžete kontaktovať na
                    emailovej adrese{' '}
                    <Link to="mailto:skolenia@perunhq.org">skolenia@perunhq.org</Link>
                </Typography>

                <ContactButton to="mailto:skolenia@perunhq.org" variant="contained" size="large">
                    Kontaktuje ma
                </ContactButton>

                <Divider />

                <Subtitle variant="h3">Obchodné údaje</Subtitle>

                <TableContainer component={Paper}>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell component="th" variant="head">
                                    Obchodné meno:
                                </TableCell>
                                <TableCell>Bc. Milan Herda Perungrad</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" variant="head">
                                    Právna forma:
                                </TableCell>
                                <TableCell>
                                    Fyzická osoba zapísaná v Živnostenskom registri Okresného úradu
                                    Nitra od 13. júla 2009
                                    <br />
                                    Číslo živnostenského registra 430-36279
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" variant="head">
                                    IČO:
                                </TableCell>
                                <TableCell>44 881 649</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" variant="head">
                                    DIČ:
                                </TableCell>
                                <TableCell>1 081 689 268</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" variant="head">
                                    Adresa:
                                </TableCell>
                                <TableCell>
                                    Kpt. Jána Rašu 3147/21
                                    <br />
                                    Bratislava-Dúbravka
                                    <br />
                                    841 01
                                    <br />
                                    Slovenská republika
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Layout>
        </>
    );
};

const ContactButton = styled(Button)(({ theme }) => ({
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
}));

const Subtitle = styled(Typography)(({ theme }) => ({
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
}));

export const query = graphql`
    query {
        site {
            siteMetadata {
                title
            }
        }
    }
`;

export default ContactPage;
